import React from 'react';
import Section from '../Section';
import SponsorItem from './SponsorItem';

export const PartnersContent = ({ sponsors }) => {
  return (
    <Section>
      <p className="px-0 text-3xl text-center font-baloo text-s4tk-blue sm:text-justify md:px-4 lg:px-8">
        {sponsors.blurb}
      </p>
      <div className="flex flex-row flex-wrap items-center justify-around w-full py-16">
        {sponsors.items.map((item, index) => (
          <a target="_blank" rel="noopener noreferrer" href={item.link ?? '/'}>
            <SponsorItem key={index} image={item.image} />
          </a>
        ))}
      </div>
    </Section>
  );
};

export default PartnersContent;
