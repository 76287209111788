import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Header from '../components/Header';
import PartnersContent from '../components/About/PartnersContent';

export const SponsorsPageTemplate = ({ title, sponsors }) => {
  return (
    <div className="flex flex-col w-full bg-s4tk-white">
      <Header title={title} />
      <PartnersContent sponsors={sponsors} />
    </div>
  );
};

const SponsorsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <SponsorsPageTemplate
        title={frontmatter.title}
        sponsors={frontmatter.sponsors}
      />
    </Layout>
  );
};

export default SponsorsPage;

export const sponsorsPageQuery = graphql`
  query SponsorsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "sponsors-page" } }) {
      frontmatter {
        title
        sponsors {
          blurb
          items {
            image {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            link
          }
        }
      }
    }
  }
`;
